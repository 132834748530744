





































import { Component, Prop, Vue } from 'vue-property-decorator'
import Sticky from '@/components/Sticky/index.vue'
import DrawMap from '@/components/GoogleMaps/draw-map.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule, DeviceType } from '@/store/modules/app'

@Component({
  name: 'SearchDrawMap',
  components: {
    Sticky,
    DrawMap
  }
})

export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: false }) private polygon!: any

  get center() {
    return UserModule.officeCoords
  }

  get drawerSize() {
    if (AppModule.device === DeviceType.Desktop) {
      return 50
    }
    return 100
  }

  private emitPolygon() {
    const childComp = this.$refs.map as any
    this.$emit('add-polygon', childComp.paths)
    this.handleClose()
  }

  private handleClose() {
    this.$emit('close')
  }
}
