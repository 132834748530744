




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Loader } from '@googlemaps/js-api-loader'
// eslint-disable-next-line no-undef
import MapOptions = google.maps.MapOptions;
// eslint-disable-next-line no-undef
import MarkerOptions = google.maps.MarkerOptions;
// eslint-disable-next-line no-undef
import PolygonOptions = google.maps.PolygonOptions;

@Component({
  name: 'GoogleDrawMap'
})

export default class extends Vue {
  @Prop({ default: '600px' }) private height!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ required: false }) private searchPolygon!: any
  @Prop({ required: false }) private center!: any

  private isDrawActive = false
  private isMapDraggable = true
  private map: any
  private google: any
  private paths: any = []
  private polygon: any
  private closeButton: any

  get mapOptions() {
    return {
      zoom: 9,
      center: this.center || {
        lat: 39.0742,
        lng: 21.8243
      }
    }
  }

  mounted() {
    new Loader({
     // apiKey: 'AIzaSyDOz1rn_PcMcurA_ZrGbvqWPoQEBO_Vov0',
      apiKey: 'AIzaSyDRZet_yXdSPW1Dk4PWfNFv_uJTy0a7nHk',
      version: 'weekly',
      channel: '2'
    }).load().then((google) => {
      const mapDiv = document.getElementById('map') as HTMLElement
      this.google = google
      this.map = new google.maps.Map(mapDiv, this.mapOptions as MapOptions)
      this.searchPolygon?.length && this.addOnLoad()
      google.maps.event.addListener(this.map, 'mousemove', this.onMouseMove)
      google.maps.event.addListener(this.map, 'touchmove', this.onMouseMove)
      mapDiv.addEventListener('mousedown', this.onMouseDown)
      mapDiv.addEventListener('touchstart', this.onMouseDown)
      mapDiv.addEventListener('mouseup', this.onMouseUp)
      mapDiv.addEventListener('touchend', this.onMouseUp)
    })
  }

  private addOnLoad() {
    this.isMapDraggable = false
    this.paths = this.searchPolygon
    this.initPolygon()
    this.initCloseBtn()
  }

  private onMouseMove(event: any) {
    if (this.isMapDraggable || !this.isDrawActive) {
      return
    }
    this.paths.push({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    })
    this.initPolygon()
  }

  private onMouseDown() {
    if (this.isMapDraggable || this.polygon) return
    this.isDrawActive = true
    this.paths = []
  }

  private onMouseUp() {
    if (this.isMapDraggable) return
    this.isDrawActive = false
    this.initCloseBtn()
  }

  private initCloseBtn() {
    if (!this.polygon) {
      return
    }
    this.clearCloseBtn()
    this.closeButton = new this.google.maps.Marker({
      position: this.paths[this.paths.length - 1],
      icon: 'close32.png',
      map: this.map
    } as MarkerOptions)
    this.closeButton.addListener('click', this.clearMap)
  }

  private initPolygon() {
    this.clearPolygon()
    this.polygon = new this.google.maps.Polygon({
      paths: this.paths,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#ffffff',
      fillOpacity: 0
    } as PolygonOptions)
    this.polygon.setMap(this.map)
  }

  private setOptions() {
    this.map.setOptions({
      draggable: this.isMapDraggable
    })
  }

  private onCancel() {
    this.clearMap()
    this.setOptions()
  }

  private onDraw() {
    this.isMapDraggable = false
    this.setOptions()
  }

  private clearPolygon() {
    if (this.polygon) {
      this.polygon.setMap(null)
      this.polygon = null
    }
    this.clearCloseBtn()
  }

  private clearCloseBtn() {
    if (this.closeButton) {
      this.closeButton.setMap(null)
      this.closeButton = null
    }
  }

  private clearMap() {
    this.paths = []
    this.clearPolygon()
    this.isMapDraggable = true
  }
}
