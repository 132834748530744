















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { deleteSearch, getSearch, saveSearch } from '@/api/searches'
import Sticky from '@/components/Sticky/index.vue'
import PropertyItem from './PropertyItem.vue'
import SearchForm from './SearchForm.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule, DeviceType } from '@/store/modules/app'
import { Search } from '@/models/Search'
import { checks, floors } from '@/utils/property'
import { getPublicAndOfflineProperties } from '@/api/properties'
import SearchDrawMap from '@/views/search/components/SearchDrawMap.vue'
import {
  CollectionRequestMeta,
  createFilter,
  FilterOperator,
  FilterType,
  GeometryType,
  SortOrder
} from '@/utils/filter'
import {
  confirmDialog,
  errorMsg,
  getOfficeLogoUrl,
  getTranslations,
  successMsg,
  validateForm
} from '@/utils'
import { Form } from 'element-ui'
import {
  getSearchesNotifications,
  setNotificationsSeen
} from '@/api/searchNotifications'

@Component({
  name: 'SearchDetail',
  components: {
    Sticky,
    SearchForm,
    PropertyItem,
    SearchDrawMap
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  private loading = false
  private skeletonLoader = false
  private drawMapVisible = false
  private selectedSort: any = null
  private page = 1
  private totalPages = 0
  private totalItems = 0
  private selected: any = []
  private newProperties = []
  private getTranslations = getTranslations
  private getOfficeLogoUrl = getOfficeLogoUrl
  private properties: any[] = []

  private sortOptions = [
    {
      name: 'date_updated',
      key: 'date_updated',
      order: SortOrder.desc
    },
    {
      name: 'price_desc',
      key: 'price',
      order: SortOrder.desc
    },
    {
      name: 'price_asc',
      key: 'price',
      order: SortOrder.asc
    },
    {
      name: 'acreage_desc',
      key: 'acreage',
      order: SortOrder.desc
    },
    {
      name: 'acreage_asc',
      key: 'acreage',
      order: SortOrder.asc
    }
  ]

  private search = new Search({
    user_id: UserModule.id,
    office_id: UserModule.officeId,
    active: true
  })

  created() {
    if (this.isEdit && this.$route.params && this.$route.params.id) {
      this.getItem(this.$route.params.id).then(() => {
        this.search.user_id = this.search.user_id || UserModule.id
        this.getNewProperties(this.$route.params.id).then(() => {
          setNotificationsSeen({
            searchId: this.search.id
          })
        })
      })
    }
  }
  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  get noMore() {
    return this.page >= this.totalPages
  }

  get disabled() {
    return this.loading || this.noMore
  }

  get areasExist() {
    return this.search.meta.areas && this.search.meta.areas.length > 0
  }

  private preparePropertyFilters() {
    const listQuery: CollectionRequestMeta = createFilter()
    this.selectedSort = this.selectedSort || this.sortOptions[0]

    if (!listQuery.query?.filters) {
      return listQuery
    }

    listQuery.query.filters.items = []
    listQuery.query.sort = [{
      field: this.selectedSort.key,
      order: this.selectedSort.order
    }]

    if (this.search.meta.areas && this.search.meta.areas.length) {
      listQuery.query.filters.items?.push({
        type: FilterType.operator,
        operator: FilterOperator.or,
        items: this.search.meta.areas.map((item: string) => {
          return {
            type: FilterType.operator,
            operator: FilterOperator.or,
            items: [
              {
                type: FilterType.field,
                key: 'area_id',
                value: item,
                operator: FilterOperator.eq
              },
              {
                type: FilterType.field,
                key: 'area.parent_id',
                value: item,
                operator: FilterOperator.eq
              },
              {
                type: FilterType.field,
                key: 'area.parent.parent_id',
                value: item,
                operator: FilterOperator.eq
              }
            ]
          }
        })
      })
    }

    if (this.search.meta.heating && this.search.meta.heating.length) {
      listQuery.query.filters.items?.push({
        type: FilterType.operator,
        operator: FilterOperator.or,
        items: this.search.meta.heating.map((item) => {
          return {
            type: FilterType.field,
            key: 'heating',
            value: item,
            operator: FilterOperator.eq
          }
        })
      })
    }

    if (this.search.meta.types && this.search.meta.types.length) {
      listQuery.query.filters.items?.push({
        type: FilterType.operator,
        operator: FilterOperator.or,
        items: this.search.meta.types.map((item) => {
          return {
            type: FilterType.field,
            key: 'type',
            value: item,
            operator: FilterOperator.eq
          }
        })
      })
    }

    if (this.search.meta.category) {
      listQuery.query.filters.items?.push({
        type: FilterType.field,
        key: 'category',
        value: this.search.meta.category,
        operator: FilterOperator.eq
      })
    }

    if (this.search.meta.available_for) {
      listQuery.query.filters.items?.push({
        type: FilterType.field,
        key: 'available_for',
        value: this.search.meta.available_for,
        operator: FilterOperator.eq
      })
    }

    if (this.search.meta.price_from || this.search.meta.price_to) {
      if (this.search.meta.price_from) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'price',
          value: this.search.meta.price_from,
          operator: FilterOperator.ge
        })
      }
      if (this.search.meta.price_to) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'price',
          value: this.search.meta.price_to,
          operator: FilterOperator.le
        })
      }
    }

    if (this.search.meta.acreage_from || this.search.meta.acreage_to) {
      if (this.search.meta.acreage_from) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'acreage',
          value: this.search.meta.acreage_from,
          operator: FilterOperator.ge
        })
      }
      if (this.search.meta.acreage_to) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'acreage',
          value: this.search.meta.acreage_to,
          operator: FilterOperator.le
        })
      }
    }

    if (this.search.meta.construction_year_from || this.search.meta.construction_year_to) {
      if (this.search.meta.construction_year_from) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'construction_year',
          value: this.search.meta.construction_year_from,
          operator: FilterOperator.ge
        })
      }
      if (this.search.meta.construction_year_to) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'construction_year',
          value: this.search.meta.construction_year_to,
          operator: FilterOperator.le
        })
      }
    }

    if (this.search.meta.rooms_from || this.search.meta.rooms_to) {
      if (this.search.meta.rooms_from) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'rooms',
          value: this.search.meta.rooms_from,
          operator: FilterOperator.ge
        })
      }
      if (this.search.meta.rooms_to) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'rooms',
          value: this.search.meta.rooms_to,
          operator: FilterOperator.le
        })
      }
    }

    if (this.search.meta.floor_from || this.search.meta.floor_to) {
      const validValues = floors.map(item => item.value)
      if (validValues.includes(this.search.meta.floor_from) && this.search.meta.floor_from !== 8) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'floor',
          value: this.search.meta.floor_from,
          operator: FilterOperator.ge
        })
      }
      if (validValues.includes(this.search.meta.floor_to)) {
        listQuery.query.filters.items?.push({
          type: FilterType.field,
          key: 'floor',
          value: this.search.meta.floor_to,
          operator: FilterOperator.le
        })
      }
    }

    if (this.search.meta.sea_distance) {
      listQuery.query.filters.items?.push({
        type: FilterType.field,
        key: 'sea_distance',
        value: this.search.meta.sea_distance,
        operator: FilterOperator.eq
      })
    }

    if (this.search.meta.ignored_properties) {
      listQuery.query.filters.items?.push({
        type: FilterType.field,
        key: 'full_code',
        value: this.search.meta.ignored_properties,
        operator: FilterOperator.not_part_of_list
      })
    }

    if (this.search.meta.polygon?.length) {
      listQuery.query.filters.items?.push({
        type: FilterType.field,
        key: 'location',
        operator: FilterOperator.intersects,
        geometry: {
          type: GeometryType.polygon,
          coordinates: [
            [
              ...this.search.meta.polygon.map((coordinates: any) => {
                return [coordinates.lat, coordinates.lng]
              }),
              [
                this.search.meta.polygon[0].lat,
                this.search.meta.polygon[0].lng
              ]
            ]
          ] as any
        }
      })
    }

    checks.forEach((check) => {
      if (this.search.meta[check]) {
        if (listQuery.query?.filters?.items) {
          listQuery.query.filters.items?.push({
            type: FilterType.field,
            key: check,
            value: true,
            operator: FilterOperator.eq
          })
        }
      }
    })

    if (listQuery.query?.pagination) {
      listQuery.query.pagination.page = this.page
    }

    return listQuery
  }

  private async getResults() {
    try {
      this.page = 1
      const listQuery = this.preparePropertyFilters()
      const { data } = await getPublicAndOfflineProperties(listQuery)
      this.properties = data.collection
      this.totalPages = data.pagination.total_pages
      this.totalItems = data.pagination.total_items
    } catch (err) {
      this.page = 1
      this.totalPages = 0
      this.totalItems = 0
      this.properties = []
    }
  }

  private hideProperty(property: any) {
    this.search.meta.ignored_properties = this.search.meta.ignored_properties || []
    this.search.meta.ignored_properties.push(property.full_code)
    this.getResults()
  }

  private async getItem(id: string) {
    this.skeletonLoader = true
    try {
      const { data } = await getSearch({ id })
      this.search = data
      await this.getResults()
    } catch (err) {}
    this.skeletonLoader = false
  }

  private async getNewProperties(searchId: string) {
    try {
      const { data } = await getSearchesNotifications({ searchId })
      this.newProperties = data
    } catch (err) {}
  }

  private async deleteItem() {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteSearch({ id: this.search.id })
      await successMsg('actions.apiDeleteSuccess')
      return this.$router.push('/search/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async sendSearchEmail() {
    return this.$router.push({
      path: '/internal-mail/create',
      query: {
        subject: 'Προτεινόμενα Ακίνητα',
        choose_from: 'clients',
        properties: this.selected.map((item: any) => item.full_code),
        recipients: [this.search.client_id]
      }
    })
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private openMapDraw() {
    this.drawMapVisible = true
  }

  private addPolygon(polygon: any) {
    this.search.meta.polygon = polygon
    this.search.meta.areas = []
  }

  private async load() {
    this.loading = true
    try {
      this.page++
      const listQuery = this.preparePropertyFilters()
      const { data } = await getPublicAndOfflineProperties(listQuery)
      this.properties.push(...data.collection)
    } catch (err) {}
    this.loading = false
  }

  private tableRowClassName(item: any) {
    if (item.row.auction) {
      return 'auction-row';
    }
    return '';
  }

  private async submitForm() {
    try {
      const searchForm = this.$refs.searchForm as any
      const [valid] = await validateForm(searchForm.$refs.form as Form)
      if (!valid) return errorMsg('form.formErrors')
      this.loading = true
      const { data } = await saveSearch(this.search)
      await successMsg('actions.apiSuccessSave')
      if (!this.isEdit) {
        return this.$router.push('/search/edit/' + data.id)
      }
    } catch (err) {
      await errorMsg('api.serverError')
    } finally {
      this.loading = false
    }
  }
}
