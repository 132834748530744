




































































































































































































































































































































































































































import { getLocale } from '@/lang'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Sticky from '@/components/Sticky/index.vue'
import ClientCard from '@/views/recommendation/components/ClientCard.vue'
import {
  businessAttributes,
  checks,
  heatingOptions,
  landAttributes,
  types,
  residenceAttributes,
  seaDistanceOptions,
  floors
} from '@/utils/property'
import { errorMsg, getTranslations, hasRoles, successMsg } from '@/utils'
import { getRemoteClients } from '@/api/helpers'
import { Client } from '@/models/Client'
import { UserModule } from '@/store/modules/user'
import { saveClient } from '@/api/clients'
import { getAreasAndMunicipalities, getAreasOrMunicipalitiesById } from '@/api/areas'
import { createFilter, FilterItem, FilterOperator, FilterType } from '@/utils/filter'
import { getPublicAndOfflineProperties } from '@/api/properties'

@Component({
  name: 'SearchForm',
  components: {
    Sticky,
    ClientCard
  }
})

export default class extends Vue {
  @Prop({ required: true }) private search: any
  private clientsLoading = false
  private clientDialog = false
  private propertiesLoading = false
  private areasLoading = false
  private types = types
  private client: Client | null = null
  private heatingOptions = heatingOptions
  private seaDistanceOptions = seaDistanceOptions
  private getTranslations = getTranslations
  private clients: any[] = []
  private properties: any[] = []
  private areas: any = []
  private rules = {
    client_id: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }]
  }

  get isLand() {
    return this.search.meta.category === 'land'
  }

  get polygonExists() {
    return this.search.meta.polygon && this.search.meta.polygon.length > 0
  }

  get attributes() {
    switch (this.search.meta.category) {
      case 'residence':
        return residenceAttributes
      case 'business':
      case 'business_roof':
        return businessAttributes
      case 'land':
        return landAttributes
      default:
        return checks
    }
  }

  get floors() {
    return floors
  }

  created() {
    if (this.search.meta.areas && this.search.meta.areas.length) {
      getAreasOrMunicipalitiesById({
        ids: this.search.meta.areas,
        locale: getLocale()
      }).then(({ data }) => {
        this.areas = data
      })
    }
    if (this.search.client) {
      this.clients = [this.search.client]
    }
  }

  addNewClient() {
    this.clientDialog = true
    this.client = new Client({
      user_id: UserModule.id,
      office_id: UserModule.officeId
    })
  }

  private onAreasSelectChange() {
    this.search.meta.polygon = []
  }

  private async saveClient(): Promise<void> {
    if (!this.client) return
    const client = this.$refs.clientCard as any
    if (client) client.normalizeItemForSave()
    this.client.user = null
    this.client.found_from = this.client.found_from || null
    this.client.profession = this.client.profession || null
    try {
      const { data } = await saveClient(this.client)
      this.search.client_id = data.id
      this.client.id = data.id
      this.clients = [this.client]
      this.clientDialog = false
      await successMsg('actions.apiSuccessSave')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async getRemoteClientsList(query: string) {
    const filterItems: FilterItem[] = this.shouldFilterByUser()
      ? [{
          type: FilterType.field,
          key: 'user_id',
          value: UserModule.id,
          operator: FilterOperator.eq
        }]
      : []
    this.clientsLoading = true
    this.clients = await getRemoteClients(query, filterItems)
    this.clientsLoading = false
  }

  private shouldFilterByUser(): boolean {
    return hasRoles(['broker'], UserModule.roles)
  }

  private async getRemoteAreasList(query: string) {
    if (!query?.trim()) {
      this.areas = []
      return
    }

    this.areasLoading = true

    try {
      const areas = await getAreasAndMunicipalities({
        key: query,
        locale: getLocale()
      })
      this.areas = this.removeDuplicateAreas(areas)
    } catch (error) {
      console.error('Error fetching areas:', error)
      this.areas = []
    } finally {
      this.areasLoading = false
    }
  }

  private removeDuplicateAreas(areas: any): any {
    const seenIds = new Set()
    return areas.filter((area: any) => {
      if (seenIds.has(area.id)) return false
      seenIds.add(area.id)
      return true
    })
  }

  private async getProperties(query: string): Promise<void> {
    const filterItems: FilterItem[] = [{
      type: FilterType.field,
      key: 'full_code',
      value: query,
      operator: FilterOperator.contains
    }]

    this.propertiesLoading = true

    try {
      const response = await getPublicAndOfflineProperties(createFilter(filterItems))
      this.properties = response.data.collection
    } catch (error) {
      console.error('Error fetching properties:', error)
      this.properties = []
    } finally {
      this.propertiesLoading = false
    }
  }
}
