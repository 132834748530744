







































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  getAreaFullTitle,
  getSubText,
  getTranslations,
  numberFormat,
  parseTime
} from '@/utils'
import {
  checks,
  getImagePreview,
  redirectToSite
} from '@/utils/property'

@Component({
  name: 'PropertyItem'
})

export default class extends Vue {
  @Prop({ required: true }) private property: any
  @Prop({ required: false, default: false }) private selected!: boolean
  @Prop({ default: true }) private showActions: any

  private getAreaFullTitle = getAreaFullTitle
  private parseTime = parseTime
  private numberFormat = numberFormat
  private getImagePreview = getImagePreview
  private redirectToSite = redirectToSite

  get description() {
    const description = this.stripHtml(getTranslations(this.property, ['description']))
    if (description) {
      return getSubText(description, 200)
    }
    return checks.map((item) => {
      return (this.property[item])
        ? this.$t('propertyDetail.' + item)
        : null
    }).filter(item => item).join(', ')
  }

  private stripHtml(html: any) {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }
}
