import { Client } from '@/models/Client'
import { User } from '@/models/User'

/* eslint-disable camelcase */
interface SearchMeta {
  category: string
  available_for: string
  areas: any[]
  heating: any[]
  types: any[]
  polygon: any
  price_from: number
  price_to: number
  acreage_from: number
  acreage_to: number
  construction_year_from: number
  construction_year_to: number
  rooms_from: number
  rooms_to: number
  floor_from: number
  floor_to: number
  levels_from: number
  levels_to: number
  airy: boolean
  penthouse: boolean
  furnished: boolean
  air_conditioning: boolean
  parking: boolean
  elevator: boolean
  warehouse: boolean
  veranda: boolean
  pool: boolean
  playroom: boolean
  fireplace: boolean
  solar_water_heater: boolean
  boiler: boolean
  garden: boolean
  attic: boolean
  sea_view: boolean
  mountain_view: boolean
  park_view: boolean
  openness_view: boolean
  safety_door: boolean
  alarm: boolean
  corner: boolean
  luxury: boolean
  floor_heating: boolean
  bright: boolean
  suitable_for_professional: boolean
  accessible_for_disabled: boolean
  painted: boolean
  suspended_ceiling: boolean
  pets_allowed: boolean
  screens: boolean
  staircase: boolean
  neoclassic: boolean
  open_house: boolean
  night_electricity: boolean
  preserved: boolean
  tent: boolean
  power_marketing: boolean
  cable_ready: boolean
  satellite_receiver: boolean
  short_term_letting: boolean
  exchange_scheme: boolean
  double_glazing: boolean
  structural_wiring: boolean
  cargo_lift: boolean
  investment: boolean
  mild_sanitation: boolean
  with_equipment: boolean
  unloading_ramp: boolean
  funnel: boolean
  agricultural_use: boolean
  student: boolean
  floor_apartment: boolean
  cottage: boolean
  sea_distance: boolean
  [key: string]: any
}

export class Search {
  id: string
  office_id: string
  client_id: string
  user_id: string
  notes: string
  active: boolean
  client: Client
  user: User
  meta: SearchMeta
  date_created: string
  date_updated: string

  constructor(data: any = {}) {
    this.id = data.id || null
    this.office_id = data.office_id || null
    this.client_id = data.client_id || null
    this.user_id = data.user_id || null
    this.notes = data.notes || null
    this.client = data.client || null
    this.user = data.user || null
    this.active = data.active || false
    this.meta = data.meta || { polygon: [] } as any
    this.date_created = data.date_created || null
    this.date_updated = data.date_updated || null
  }
}
