import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson, getTranslations } from '@/utils'

export const getSearches = (data: any) =>
  request({
    method: 'post',
    url: '/api/searchRequests/getSearches',
    data
  })

export const getSearchesCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/searchRequests/getSearchesCount',
    data
  })

export const getSearch = (params: any) =>
  request({
    method: 'get',
    url: '/api/searchRequests/getSearch',
    params
  })

export const getSearchesNewProperties = (params: any) =>
  request({
    method: 'get',
    url: '/api/searchRequests/getSearchesNewProperties',
    params
  })

export const saveSearch = (data: any) =>
  request({
    method: 'post',
    url: '/api/searchRequests/saveSearch',
    data
  })

export const saveSearchBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/searchRequests/saveSearchBulk',
    data
  })

export const deleteSearch = (params: any) =>
  request({
    method: 'get',
    url: '/api/searchRequests/deleteSearch',
    params
  })

export const deleteSearchBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/searchRequests/deleteSearchBulk',
    data
  })

export const exportSearches = (searches: any) => {
  const fields = [
    {
      key: 'user',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user)
      }
    },
    {
      key: 'acreage_from'
    },
    {
      key: 'acreage_to'
    },
    {
      key: 'price_from'
    },
    {
      key: 'price_to'
    },
    {
      key: 'construction_year_from'
    },
    {
      key: 'construction_year_to'
    },
    {
      key: 'date_created'
    }
  ]
  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, searches),
    'search-list'
  )
}
