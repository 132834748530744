



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { convertToArrayTranslations, convertToMapTranslations, locales } from '@/utils'
import { ClientTranslation } from '@/models/ClientTranslation'

@Component({
  name: 'ClientCard'
})

export default class extends Vue {
  @Prop({ required: true }) private client: any
  @Prop({ required: false, default: 12 }) private col: any
  private language = 'el'
  private translations = convertToMapTranslations(this.$props.client.translations || [], ClientTranslation)
  private atLeastOne = (rule: any, value: string, callback: Function) => {
    const isValid = locales.some((language) => {
      return this.translations[language][rule.field]
    })
    return isValid ? callback() : callback(this.$t('form.isRequired'))
  }

  private atLeastOnePhone = (rule: any, value: string, callback: Function) => {
    return this.hasAtLeastOnPhone() ? callback() : callback(this.$t('form.atLeastOnePhone'))
  }

  private rules = {
    first_name: [{
      validator: this.atLeastOne,
      trigger: 'blur'
    }],
    last_name: [{
      validator: this.atLeastOne,
      trigger: 'blur'
    }],
    main_email: [{
      type: 'email',
      message: this.$t('form.isEmail'),
      trigger: 'blur'
    }],
    main_phone: [{
      validator: this.atLeastOnePhone,
      trigger: 'blur'
    }],
    secondary_phone: [{
      validator: this.atLeastOnePhone,
      trigger: 'blur'
    }]
  }

  private normalizeItemForSave() {
    this.client.translations = convertToArrayTranslations(
      this.translations,
      ClientTranslation
    )
  }

  private hasAtLeastOnPhone() {
    return this.client.main_phone || this.client.secondary_phone
  }
}
