import request from '@/utils/request'

export const setNotificationsSeen = (params: any) =>
  request({
    method: 'get',
    url: '/api/searchNotifications/setNotificationsSeen',
    params
  })

export const getSearchesNotifications = (params: any) =>
  request({
    method: 'get',
    url: '/api/searchNotifications/getSearchesNotifications',
    params
  })
